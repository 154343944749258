import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    position: 'relative'
  },
  divider: {
    background: 'none',
    borderTop: `1px dashed ${theme.palette.primary.main}`,
  }
}));