import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Carregando, Botao } from 'componentes';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import * as merged from 'api/merged';
import { EditarConfiguracaoForm } from '../editar-configuracoes-form';
import { useNotificacoes } from 'componentes';
import { EditarDadosInstancia } from './edicao-dados-instancia';
import SaveIcon from '@material-ui/icons/Save';
import { useStyles } from './edicao-dados-styles';
import { useGetEmpresa } from 'api/cardapio/queries/empresa/get-empresa';
import { TemaModel } from 'api/cardapio/models/tema/tema-model';

export interface EditarDadosModalProps {
  aberto: boolean;
  empresaId: string;
  quandoForFechado: any;
  adicionarEmpresa: string;
  values: TemaModel;
  redefinir: boolean;
  logout: () => void;
  setValues: (values: React.SetStateAction<TemaModel>) => void;
  setRedefinir: (valor: boolean) => void;
}

export const EditarDados = ({
  aberto,
  empresaId,
  quandoForFechado,
  adicionarEmpresa,
  values,
  redefinir,
  logout,
  setValues,
  setRedefinir
}: EditarDadosModalProps) => {
  const classes = useStyles();
  const { showErrorMessage } = useNotificacoes();
  //queries
  const { refetch: buscarEmpresaDados, isLoading: carregandoEmpresaDados, ...empresaDados } = useGetEmpresa(({ empresaId: empresaId }),
    {
      enabled: empresaId,
    },
  );
  const [carregando, setCarregando] = useState(true);
  const refFormDados = useRef<EditarDadosInstancia>(null);
  const [merged, setMerged] = useState<merged.MergedCardapio>({} as merged.MergedCardapio);
  const [open, setOpen] = useState<boolean>(false);

  //controle do carregando
  useEffect(() => {
    if (aberto) {
      const pausar = setTimeout(() => {
        setCarregando(false);
      }, 500);
      return () => clearTimeout(pausar)
    }
  }, [aberto]);

  useEffect(() => {
    if (aberto && !carregandoEmpresaDados) {
      setMerged({
        empresa: empresaDados.data?.empresa,
        configuracoes: empresaDados.data?.configuracoes,
        configuracoesDelivery: empresaDados.data?.configuracoesDelivery,
        finalizadoras: empresaDados.data?.finalizadoras
      } as merged.MergedCardapio);
    }
  }, [aberto, empresaDados.data, carregandoEmpresaDados]);

  const fechar = useCallback(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
    setTimeout(() => {
      setCarregando(true);

      quandoForFechado();
    }, 500);
  }, [quandoForFechado]);

  const submitForms = useCallback(() => {
    setCarregando(true);

    if (refFormDados) {
      let hasErrors = refFormDados.current?.submit();

      if (hasErrors) {
        try {
          setCarregando(false)
          let message = Object.values(hasErrors[Object.keys(hasErrors)[0]])[0] as string;
          showErrorMessage(message || 'Preencha todos os dados');
        } catch {
          showErrorMessage('Preencha todos os dados');
        }
      }
    }
    setCarregando(false);
  }, [showErrorMessage]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const redefinirClick = useCallback(async () => {
    setRedefinir(true);
    handleClose();
  }, [setRedefinir])

  if (carregando) {
    return (
      <Carregando
        className={classes.carregando}
        carregando={true}
        modelo="normal"
        titulo={
          carregandoEmpresaDados
            ? 'Salvando Configurações'
            : 'Carregando configurações'
        }
        mensagem={
          carregandoEmpresaDados
            ? 'Por favor aguarde enquanto salvamos as informações...'
            : 'Por favor aguarde enquanto carregamos as informações...'
        }
      ></Carregando>
    );
  }

  return (
    <Container className={classes.container} fixed>
      <EditarConfiguracaoForm
        ref={refFormDados}
        empresaDados={merged}
        redefinir={redefinir}
        setValueProps={setValues}
        setRedefinir={setRedefinir}
      />
      <div className={classes.fab}>
        <Grid container>
          <Grid item xs={12} style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
            <Botao
              onClick={handleClickOpen}
              color='secondary'
              style={{
                marginRight: '10px'
              }}>

              Redefinir
            </Botao>
            <Botao
              color="secondary"
              style={{
                marginRight: '10px'
              }}
              onClick={fechar}>
              Voltar
            </Botao>
            <Botao
              carregando={carregando}
              color="primary"
              onClick={submitForms}
            >
              <SaveIcon style={{ marginRight: '5px' }} />
              Salvar
            </Botao>
          </Grid>
        </Grid>
      </div>
      {open && (
        <Dialog aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle>{"Redefinir as suas configurações"}</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              Você deseja realmente redefinir as cores do tema?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="secondary">
              Não
            </Button>
            <Button autoFocus onClick={redefinirClick} color={'primary'}>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};
