import { EnumTipoProduto } from "./mov-simples-produto-model";

export class ProdutoEntregaModel {
    constructor(
        public categoriaId: string | null = null,
        public cnpjFab: string | null = null,
        public cobraTaxaServico: boolean = false,
        public descricao: string = '',
        public favorito: boolean = false,
        public grades: ProdutoEntregaGradeModel[] = [],
        public id: string | null = null,
        public indEstala: number = 0,
        public infAdic: string | null = null,
        public marca: string | null = null,
        public ncmId: string | null = null,
        public nome: string | null = null,
        public orig: number | null = 0,
        public setorId: string | null = null,
        public tipo: EnumTipoProduto = EnumTipoProduto.Produto,
    ) { }
}

export class ProdutoEntregaGradeModel {
    constructor(
        public ativo: boolean = false,
        public balanca: number = 0,
        public codigo: string | null = null,
        public codigoAnvisa: string | null = null,
        public contratoId: string = '',
        public empresaId: string = '',
        public grade: string | null = null,
        public id: string = '',
        public infAdic: string | null = null,
        public medidaEntradaId: string = '',
        public medidaSaidaId: string = '',
        public produtoId: string = '',
        public qMaximo: number = 0,
        public qMinimo: number = 0,
        public qSaldoTotal: number = 0,
        public tabelaPrecoId: string = '',
        public vCompra: number | null = null,
        public vMinimo: number | null = null,
        public vPreco: number | null = null,
    ) { }
}