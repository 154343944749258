import { useValidationYupDocumento } from 'utils/form-validations';
import * as Yup from 'yup';

export const useNovaPessoaFormValidation = (delivery: boolean) => {
  const { documentoYup } = useValidationYupDocumento(true)
  const novaPessoaFormValidation = Yup.object().shape({
    nome: Yup.string().notRequired(),
    cpfcnpj: documentoYup(),
    telefone: delivery
      ? Yup.string().required('Número de telefone é obrigatório').matches(/^\(\d{2}\) \d{4,5}-\d{4}$/, 'Número de telefone inválido')
      : Yup.string().nullable().matches(/^\(\d{2}\) \d{4,5}-\d{4}$/, 'Número de telefone inválido'),
    email: Yup.string().notRequired().email('Digite um e-mail válido'),
  });

  return {
    novaPessoaFormValidation
  }
}