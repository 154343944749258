import React from 'react';
import { useCallback, useState } from 'react';
import { useStyles } from './card-produto-subitem-styles';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { toDecimalString } from 'utils/to-decimal';
import { AddESub } from './components/add-e-sub/button-add-e-sub';
import { MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import { MovSimplesModificadoresProdutoModel } from 'api/cardapio/models/produto/mov-simples-modificadores-produto-model';
import { Typography, Grid } from '@material-ui/core';

type CardSubitemProps = {
  produto: MovSimplesProdutoModel;
  handleClickAddOrSub: (
    valorProdutoPai: number,
    produto: MovSimplesProdutoModel,
    qtd: number,
    modificador?: MovSimplesModificadoresProdutoModel,
    prodGradeId?: string,
    fator?: 'add' | 'sub'
  ) => Promise<void>;
  verificar?: boolean;
  showValue?: boolean;
  edit?: boolean;
  qtdPai: number;
  qtdModificador?: number;
  valorProdutoPai: number
};

export const CardSubitem = ({
  produto,
  handleClickAddOrSub,
  verificar,
  showValue,
  edit,
  qtdPai,
  qtdModificador,
  valorProdutoPai = 0
}: CardSubitemProps) => {
  // STATES E REFS
  const [qtd, setQtd] = useState<number>(produto.qCom);

  // AUX
  const classes = useStyles();

  const imagem = useCallback(
    (imagemUrl?: string) => {
      try {
        if (imagemUrl && imagemUrl?.length > 0) {
          return (
            <img
              src={imagemUrl}
              className={classes.cardImg}
              alt={produto.xProd}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = SemImagem;
              }}
            />
          );
        }

        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = SemImagem;
            }}
          />
        );
      } catch (err) {
        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
          />
        );
      }
    },
    [classes.cardImg, produto.xProd]
  );

  if (edit && verificar && produto.qCom < 0) {
    return null;
  }
  console.log(produto.contemSubItem)
  return (
    <div className={classes.cardContainer}>
      {((produto.subItens && produto.subItens.length > 0) || produto.contemSubItem) && (
        <Typography
          variant="caption"
          className={classes.textInfoPersonalizavel}
        >
          Personalizável
        </Typography>
      )}

      <Grid
        style={{
          marginTop: produto.subItens.length > 0 || produto.contemSubItem ? '10px' : 0,
          display: 'flex',
          marginBottom: produto.subItens.length > 0 ? 4 : 0
        }}
      >
        <Grid style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          {imagem(produto.imgUrl)}

          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 4,
              justifyContent: 'center'
            }}
          >
            <Typography
              variant="body1"
              style={{ fontWeight: 600, fontSize: '14px' }}
            >
              {produto.xProd}
            </Typography>
            <Typography
              variant="body1"
              style={{ fontWeight: 400, fontSize: '12px' }}
            >
              {verificar &&
                showValue &&
                ` + R$ ${toDecimalString(
                  (produto.qComModificador > 0
                    ? produto.qComModificador
                    : qtd) * produto.vUnCom ?? 0,
                  2
                )} `}

              {produto.infoSubItem!.qPadrao >= qtd || verificar
                ? ''
                : ` + R$ ${toDecimalString(
                  ((produto.qComModificador > 0
                    ? produto.qComModificador
                    : qtd) -
                    produto.infoSubItem!.qPadrao) *
                  produto.vUnCom ?? 0,
                  2
                )} `}
            </Typography>
          </Grid>
        </Grid>
        <Grid style={{ display: 'flex', justifyContent: 'center' }}>
          <AddESub
            qtd={qtd}
            setQtd={setQtd}
            model={produto}
            alterarQtdeProduto={handleClickAddOrSub}
            verificar={verificar}
            qtdModificador={qtdModificador}
            valorProdutoPai={valorProdutoPai}
          />
        </Grid>
      </Grid>
    </div>
  );
};
