import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { EnumFasesSacola } from "../../../cardapio-sacola";
import { Box, Button, CircularProgress, Divider, Grid, IconButton, Slide, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useStyles } from './identificacao-cliente-styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ClienteIcon } from "utils";
import { TextFieldSaurus } from "componentes/inputs/text-field-saurus";
import { ContatoModel, PessoaModel, PessoaModelForm } from "api/cardapio/models/pessoa/pessoa-model";
import { guidEmpty } from "utils/guid-empty";
import { EnumTipoContato } from "api/cardapio/models/enums/enum-tipo-contato";
import { usePedidos } from "componentes/providers/pedidos-provider";
import { useNotificacoes } from "componentes";
import { useGetPessoas } from "api/cardapio/queries/pessoa/get-pessoas";
import { NovaPessoaFormPropsRef } from "./components/nova-pessoa-form/nova-pessoa-form-props";
import { usePostNovaPessoa } from "api/cardapio/queries/pessoa/post-nova-pessoa";
import { newGuid } from "utils/new-guid";
import { CardapioStorageKeys, useCardapioStorage } from "utils/cardapio-storage";
import { CardapioDadosProps } from "modulos/cardapio/containers/cardapio/cardapio-header/cardapio-header-props";
import { usePutPessoa } from "api/cardapio/queries/pessoa/put-pessoa";
import { OkIcon } from "utils/Icons/ok-icon";
import { ContinuarIcon } from "componentes/icons/continuar-icon";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { getIn, useFormik } from "formik";
import { useIdentificacaoClienteFormValidation } from "./identificacao-cliente-validation";
import { useParams } from "react-router-dom";
import { NovaPessoaForm } from "./components/nova-pessoa-form";
import { phoneMask } from "utils/phone-mask";
import { useEventTools } from "hooks/event-tools/event-tools";
import { AppEventEnum } from "hooks/event-tools/app-event-enum";
import { DadosDelivery, EnumOpcoesDelivery, useAtualizarDadosDelivery, useObterDadosDelivery } from "storage";
import { CarregandoInformacoes } from "./components/carregando-informacoes/carregando-informacoes";
import { stringNumeros } from "utils/string-numeros";

export interface IdentificacaoClienteProps {
    faseSacola: EnumFasesSacola;
    entrarFases: (fase: EnumFasesSacola) => void
    fazerPedido: () => void
    fecharSacola: () => void
    fecharModalDelivery: (fecharSacola?: boolean) => void
    abrirModalDelivery: () => void
    tipoDelivery: boolean
    pedidoDelivery: boolean
    dadosDelivery: DadosDelivery | undefined
    mesaId: string
    editarUsuarioEnderecoRef: boolean
}

export interface IdentificacaoClienteFormProps {
    cpfCnpj: string
}

export const IdentificacaoCliente = ({
    entrarFases,
    faseSacola,
    fazerPedido,
    fecharSacola,
    pedidoDelivery,
    tipoDelivery,
    abrirModalDelivery,
    dadosDelivery,
    mesaId,
    editarUsuarioEnderecoRef
}: IdentificacaoClienteProps) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { showErrorMessage, showSuccessMessage } = useNotificacoes();
    const { setCliente, getCliente, addOuAttProdutoEntrega, carregando: carregandoCalularFrete } = usePedidos();
    const refForm = useRef<NovaPessoaFormPropsRef>(null);
    const { getPessoas, carregando: carregandoGetPessoas } = useGetPessoas();
    const { getRegistro } = useCardapioStorage()
    const nextOrBack = useRef<0 | 1>(1)
    const { empresaId } = useParams<{ empresaId: string }>();
    const { postNovaPessoa, carregando: carregandoPostPessoa, } = usePostNovaPessoa();
    const { putPessoa, carregando: carregandoPutPessoa } = usePutPessoa();
    const { addHandler, removeHandler } = useEventTools()
    const deliveryDados = useObterDadosDelivery({ empresaId: empresaId });
    const [atualizarDados] = useAtualizarDadosDelivery();
    const [dDados, setDDados] = useState(deliveryDados.data)
    const recarregaRef = useRef<boolean>(true)

    const { identificacaoClienteFormValidation } = useIdentificacaoClienteFormValidation()

    const carregando = [carregandoGetPessoas, carregandoPostPessoa, carregandoPutPessoa, carregandoCalularFrete].includes(
        true
    );

    const cardapioDados = getRegistro(
        CardapioStorageKeys.DadosGerais,
        false
    ) as CardapioDadosProps;

    const empresa = cardapioDados.empresa

    const cliente = getCliente();

    const colorSacola = useMemo(() => {
        return theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.text.primary;
    }, [theme.palette.primary.main, theme.palette.text.primary, theme.palette.type]);

    const faseIdentificacao = faseSacola === EnumFasesSacola.IdentificarCliente

    const [value, setValue] = useState("");
    const [modoCadastro, setModoCadastro] = useState<boolean>(false);

    useEffect(() => {
        if (faseIdentificacao) {
            setValue("");
            setCliente(null);
            setModoCadastro(false);
        }
    }, [faseIdentificacao, setCliente]);

    const salvarStorage = useCallback((dados: {
        nome: string
        documento: string
        telefone: string
    }) => {
        if (mesaId) return false
        let delivery = (deliveryDados?.data) as DadosDelivery | undefined
        let dadosAtualizar

        if (delivery) {
            dadosAtualizar = {
                ...delivery,
                ...dados
            };
        } else {
            dadosAtualizar = {
                ...dados,
                bairro: '',
                cep: '',
                cpl: '',
                lgr: '',
                municipio: '',
                nro: '',
                tipo: EnumOpcoesDelivery.Entrega,
                uf: '',
                idEmpresa: empresaId,
                referencia: '',
            }
        }

        if (stringNumeros(dados.documento) !== stringNumeros((delivery?.documento ?? ''))) {
            dadosAtualizar = {
                ...dados,
                bairro: '',
                cep: '',
                cpl: '',
                lgr: '',
                municipio: '',
                nro: '',
                tipo: EnumOpcoesDelivery.Entrega,
                uf: '',
                idEmpresa: empresaId,
                referencia: '',
            }
        }
        atualizarDados({ dados: dadosAtualizar, empresaId: empresaId! });
        if (!(dadosAtualizar && !dadosAtualizar.bairro && !dadosAtualizar.lgr)) {
            return true
        } else {
            return false
        }
    }, [atualizarDados, deliveryDados?.data, empresaId, mesaId])

    const proximo = useCallback(() => {
        nextOrBack.current = 1

        setTimeout(() => {
            fazerPedido()
            recarregaRef.current = true
        }, 50)

        nextOrBack.current = 0
    }, [fazerPedido])

    const voltar = useCallback(() => {
        nextOrBack.current = 0

        setTimeout(() => {
            entrarFases(EnumFasesSacola.Sacola)
            recarregaRef.current = true
        }, 50)

        nextOrBack.current = 1
    }, [entrarFases])

    const isDelivery = pedidoDelivery && tipoDelivery

    const clickSubmit = useCallback(async (model: IdentificacaoClienteFormProps) => {
        let existeEndereco = true
        if (isEmpty(model.cpfCnpj)) {
            if (isDelivery && !mesaId) {
                showErrorMessage('O CPF/CNPJ é obrigatório para se identificar')
                return
            }
            proximo()
            return
        }
        const valor = model.cpfCnpj.replace(/\D/g, "");
        if (valor.length === 11 || valor.length === 14) {
            setValue(model.cpfCnpj)
            try {
                const res = await getPessoas(`?cpfCnpj=${valor}`, empresaId);
                if (res.erro) throw res.erro;

                const pessoa = res.resultado?.data as PessoaModel

                if (isEmpty(pessoa)) {
                    setModoCadastro(true);
                    return;
                }

                if ((pessoa.contatos?.length ?? 0) !== 2) {
                    //Tive que fazer isso, pois o valor padrão não estava vindo a mascara e por isso estava bugando
                    pessoa.contatos.filter(x => x.tipo === EnumTipoContato.TELEFONE).map(item => {
                        item.valor = phoneMask(item.valor)
                        return item
                    })
                    existeEndereco = salvarStorage({
                        documento: pessoa?.cpfcnpj ?? '',
                        nome: pessoa?.nome ?? '',
                        telefone: pessoa?.contatos.find(x => x.tipo === EnumTipoContato.TELEFONE)?.valor ?? ''
                    })
                    setCliente(pessoa);
                    setModoCadastro(true);
                    return;
                }

                existeEndereco = salvarStorage({
                    documento: pessoa?.cpfcnpj ?? '',
                    nome: pessoa?.nome ?? '',
                    telefone: pessoa?.contatos.find(x => x.tipo === EnumTipoContato.TELEFONE)?.valor ?? ''
                })
                setCliente(pessoa);
                showSuccessMessage("Cliente Identificado!");
                if (!mesaId) {
                    if (isDelivery) {
                        if (!editarUsuarioEnderecoRef && existeEndereco) {
                            if (dadosDelivery?.tipo === EnumOpcoesDelivery.Entrega) {
                                await addOuAttProdutoEntrega(dadosDelivery)
                            }
                        } else {
                            abrirModalDelivery()
                            return
                        }
                    }
                }
                proximo()
            } catch (err: any) {
                showErrorMessage(err.message);
            }
        }
    }, [abrirModalDelivery, addOuAttProdutoEntrega, dadosDelivery, editarUsuarioEnderecoRef, empresaId, getPessoas, isDelivery, mesaId, proximo, salvarStorage, setCliente, showErrorMessage, showSuccessMessage]);

    const submitNovaPessoa = async (model: PessoaModelForm) => {
        try {
            let existeEndereco = true
            if (!cliente) {
                const pessoa = new PessoaModel();
                pessoa.contratoId = empresa.contratoId;
                pessoa.nome = model.nome;
                pessoa.cpfcnpj = model.cpfcnpj;
                if (!isEmpty(model.telefone.replace(/\D/g, ""))) {
                    pessoa.contatos.push(new ContatoModel(
                        guidEmpty(),
                        guidEmpty(),
                        EnumTipoContato.TELEFONE,
                        model.telefone.replace(/\D/g, "")
                    ))
                }
                if (!isEmpty(model.email)) {
                    pessoa.contatos.push(
                        new ContatoModel(
                            guidEmpty(),
                            guidEmpty(),
                            EnumTipoContato.EMAIL,
                            model.email
                        ),
                    );
                }

                const res = await postNovaPessoa(pessoa, empresaId);
                if (res.erro) throw res.erro;
                const resultado = res.resultado?.data as PessoaModel | null
                existeEndereco = salvarStorage({
                    documento: resultado?.cpfcnpj ?? '',
                    nome: resultado?.nome ?? '',
                    telefone: resultado?.contatos.find(x => x.tipo === EnumTipoContato.TELEFONE)?.valor ?? ''
                })
                setCliente(resultado);
                showSuccessMessage("Cadastrado e selecionado com sucesso!");
            } else {
                const contatos: ContatoModel[] = [];

                if (!isEmpty(model.telefone.replace(/\D/g, ""))) {
                    contatos.push(new ContatoModel(
                        newGuid(),
                        guidEmpty(),
                        EnumTipoContato.TELEFONE,
                        model.telefone.replace(/\D/g, "")
                    ))
                }
                if (!isEmpty(model.email)) {
                    contatos.push(
                        new ContatoModel(
                            newGuid(),
                            guidEmpty(),
                            EnumTipoContato.EMAIL,
                            model.email
                        ),
                    );
                }
                const attCliente = { ...cliente, nome: model.nome, cpfcnpj: model.cpfcnpj, contatos: contatos }
                const res = await putPessoa(attCliente, empresaId);
                if (res.erro) throw res.erro;
                existeEndereco = salvarStorage({
                    documento: attCliente?.cpfcnpj ?? '',
                    nome: attCliente?.nome ?? '',
                    telefone: attCliente?.contatos.find(x => x.tipo === EnumTipoContato.TELEFONE)?.valor ?? ''
                })
                setCliente(attCliente);
                showSuccessMessage("Atualizado e selecionado com sucesso!");
            }

            if (!mesaId)
                if (isDelivery) {
                    if (!editarUsuarioEnderecoRef && existeEndereco) {
                        if (dadosDelivery?.tipo === EnumOpcoesDelivery.Entrega) {
                            await addOuAttProdutoEntrega(dadosDelivery)
                        }
                    } else {
                        abrirModalDelivery()
                        return
                    }
                }
            proximo();
        } catch (err: any) {
            showErrorMessage(err.message);
        }
    };

    const {
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched
    } = useFormik<IdentificacaoClienteFormProps>({
        enableReinitialize: true,
        validationSchema: identificacaoClienteFormValidation,
        initialValues: {
            cpfCnpj: !mesaId && dDados ? stringNumeros(dDados.documento) : ''
        },
        onSubmit: clickSubmit,
    });

    const handlePreencheuEndereco = useCallback((value: boolean) => {
        if (value) {
            proximo()
        }
    }, [proximo])

    const carregarInfoPessoaEndereco = useCallback(async (dados: DadosDelivery | undefined) => {
        try {
            const res = await getPessoas(`?cpfCnpj=${stringNumeros(dados?.documento ?? '')}`, empresaId);
            if (res.erro) throw res.erro;

            const pessoa = res.resultado?.data as PessoaModel
            setCliente(pessoa)

            if ((dados && dados.tipo === EnumOpcoesDelivery.Entrega && !dados.bairro && !dados.lgr)) {
                abrirModalDelivery()
                return
            }
            if (dados && dados.tipo === EnumOpcoesDelivery.Entrega) {
                await addOuAttProdutoEntrega(dDados)
            }

            proximo()
        } catch (error: any) {
            showErrorMessage(error.message);
        }
    }, [abrirModalDelivery, addOuAttProdutoEntrega, dDados, empresaId, getPessoas, proximo, setCliente, showErrorMessage])

    useEffect(() => {
        addHandler(AppEventEnum.InformadoEnderecoDelivery, handlePreencheuEndereco)

        return () => removeHandler(AppEventEnum.InformadoEnderecoDelivery, handlePreencheuEndereco)
    }, [addHandler, handlePreencheuEndereco, removeHandler])

    useEffect(() => {
        if (faseIdentificacao && !mesaId) {
            let dados = dDados
            if (recarregaRef.current) {
                setDDados(deliveryDados?.data)
                dados = deliveryDados?.data
                recarregaRef.current = false
            }
            if (dDados && !editarUsuarioEnderecoRef) {
                carregarInfoPessoaEndereco(dados)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faseIdentificacao])


    return (
        <Slide direction={nextOrBack.current === 1 ? "left" : 'right'} mountOnEnter unmountOnExit in={faseIdentificacao}>
            <Box height={"100%"} width={"100%"} className={classes.boxContainer}>
                <div className={classes.header}>
                    <IconButton
                        onClick={() => voltar()}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                        }}
                    >
                        <ArrowBackIosIcon
                            style={{ paddingLeft: '7px', fontSize: '32px', color: colorSacola }}
                        />
                    </IconButton>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            style={{
                                marginRight: isMobile ? "10px" : "8px",
                                display: "flex",
                            }}
                        >
                            <ClienteIcon tipo="INPUT" fill={colorSacola} />
                        </div>
                        <label
                            htmlFor="NomeNoPedido"
                            style={{
                                fontWeight: "bold",
                                fontSize: "24px",
                                fontStyle: "revert",
                                color: colorSacola
                            }}
                        >
                            DADOS DO CLIENTE
                        </label>
                    </div>
                </div>
                <Divider style={{ marginTop: '1rem' }} />
                {!mesaId && dDados && !editarUsuarioEnderecoRef ? (
                    <CarregandoInformacoes />
                ) : (
                    <>
                        <div className={classes.scrollable} style={{
                            height: '100%',
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            {!modoCadastro ? (
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            paddingBottom: "16px",
                                        }}
                                    >
                                        <form
                                            onSubmit={handleSubmit}
                                            style={{ width: '100%' }}
                                        >
                                            <TextFieldSaurus
                                                fullWidth
                                                color="secondary"
                                                placeholder="CPF/CNPJ"
                                                name="cpfCnpj"
                                                className={classes.textFieldAlignCenter}
                                                value={values.cpfCnpj}
                                                tipo="CNPJ_CPF"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                error={Boolean(
                                                    getIn(touched, 'cpfCnpj') &&
                                                    getIn(errors, 'cpfCnpj'),
                                                )}
                                                helperText={
                                                    getIn(touched, 'cpfCnpj') &&
                                                        getIn(errors, 'cpfCnpj')
                                                        ? getIn(errors, 'cpfCnpj')
                                                        : undefined
                                                }
                                                allowSubmit
                                            />
                                            <button type="submit" style={{ display: "none" }}></button>
                                        </form>
                                    </div>
                                    <div>
                                        <Typography
                                            variant="body1"
                                            color="textPrimary"
                                            style={{
                                                display: "flex",
                                                textAlign: "center",
                                                marginBottom: "25px",
                                            }}
                                        >
                                            Informe seu CPF/CPF{tipoDelivery && pedidoDelivery ? '' : '(opcional)'} para vincular seus dados ao pedido.
                                            <br />
                                            {!(tipoDelivery && pedidoDelivery) && (
                                                'Caso não deseje informar, mantenha em branco e clique em continuar.'
                                            )}
                                        </Typography>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <Typography align="center">
                                        {cliente
                                            ? `Vimos que as informações não foram totalmente preenchidas, ${tipoDelivery && pedidoDelivery
                                                ? 'complete suas informação para continuar com o pedido.'
                                                : 'pode completar suas informações ou continuar com o pedido'
                                            }.`
                                            : 'Faça um novo cadastro para se identificar nos próximos pedidos!'}
                                    </Typography>
                                    <NovaPessoaForm
                                        ref={refForm}
                                        model={value}
                                        onSubmit={submitNovaPessoa}
                                        {...{
                                            tipoDelivery,
                                            pedidoDelivery
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={classes.footer}>
                            <Divider style={{ marginTop: '1rem' }} />
                            <Grid container justify="center" className={classes.grdBtns}>
                                <Button
                                    onClick={() => voltar()}
                                    color={theme.palette.type === 'light' ? "primary" : 'inherit'}
                                    variant="outlined"
                                    size={isMobile ? 'medium' : 'large'}
                                    fullWidth
                                    className={classNames(classes.btnFooter, classes.btnVoltar)}
                                    disabled={carregando}
                                    startIcon={
                                        <ContinuarIcon
                                            tipo={
                                                theme.palette.type === "light"
                                                    ? "BUTTON"
                                                    : "BUTTON_PRIMARY"
                                            }
                                        />
                                    }
                                >
                                    Voltar
                                </Button>
                                <Button
                                    onClick={
                                        modoCadastro
                                            ? () => {
                                                refForm.current?.submit();
                                            }
                                            : () => handleSubmit()
                                    }
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    className={classNames(classes.btnFooter, classes.buttonPedir)}
                                    fullWidth
                                    disabled={carregando}
                                    startIcon={
                                        carregando ? (
                                            <CircularProgress size={25} />
                                        ) : modoCadastro ? (
                                            <OkIcon tipo="BUTTON_PRIMARY" />
                                        ) : (
                                            <ContinuarIcon tipo="BUTTON_PRIMARY" />
                                        )
                                    }
                                >
                                    {modoCadastro ? cliente ? 'Continuar' : "Finalizar Cadastro" : "Continuar"}
                                </Button>
                            </Grid>
                        </div>
                    </>
                )}
            </Box>
        </Slide >
    )
}