import React from "react"
import { useStyles } from "./carregando-informacoes-styles"
import { Box, Typography } from "@material-ui/core"
import { Carregando } from "componentes"

export const CarregandoInformacoes = () => {

    const classes = useStyles()

    return (
        <div className={classes.scrollable} style={{
            height: '100%',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Box>
                <Box mb={4}>
                    <Typography variant="h5" className={classes.textLoad}>
                        Carregando Informações
                    </Typography>
                </Box>
                <Carregando
                    tipo="spinner"
                    height={50}
                    width={50}
                    modelo="normal"
                    carregando
                />
            </Box>
        </div>
    )
}