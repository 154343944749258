import { EnumFasesSacola } from "../../../cardapio-sacola";
import { Box, Button, Divider, Grid, LinearProgress, Slide, Typography, useTheme } from "@material-ui/core";
import { useStyles } from './pagamento-pix-styles'
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNotificacoes } from 'componentes';
import { usePedidos } from 'componentes/providers/pedidos-provider';
import { PagamentoPixDetalhes } from './components/pagamento-pix/pagamento-pix-detalhes';
import { MovRegistrarResponseModel } from 'api/cardapio/models/mov-pag/mov-registrar-response';
import { useGetMovPagamentoById } from 'api/cardapio/queries/mov-pag/get-mov-pagamento-by-id';
import { NaEntregaIcon } from "componentes/icons/na-entrega-icon";
import classNames from "classnames";
import { useConfirm } from "material-ui-confirm";
import { useParams } from 'react-router-dom';

export interface PagamentoPixProps {
    faseSacola: EnumFasesSacola;
    entrarFases: (fase: EnumFasesSacola) => void;
    fecharSacola: () => void;
}

export enum StatusPagamento {
    Aberto,
    Pago,
    Cancelado
}

export enum EnumStatusPix {
    Aceito,
    Cancelado,
    Neutro
}

export const PagamentoPix = ({
    entrarFases,
    faseSacola,
    fecharSacola,
}: PagamentoPixProps) => {
    const classes = useStyles()
    const theme = useTheme()
    const confirm = useConfirm()
    const fasePagamento = faseSacola === EnumFasesSacola.PagamentoPix
    const nextOrBack = useRef<0 | 1>(0)

    const {
        handlePedidoPix,
        dadosPix,
        carregandoHandlePedidoPix,
        handlePedido,
        cancelarPedido
    } = usePedidos()

    const { getMovPagamentoById } = useGetMovPagamentoById()
    const [idInterval, setIdInterval] = useState<NodeJS.Timeout>()
    const [cancelarInterval, setCancelarInterval] = useState<boolean>(false)
    const [progress, setProgress] = useState(0);
    const [statusPix, setStatusPix] = useState<EnumStatusPix>(EnumStatusPix.Neutro)
    const { showErrorMessage, showSuccessMessage } = useNotificacoes()
    const [pedidoErro, setPedidoErro] = useState<boolean>(false);
    const { empresaId } = useParams<{ empresaId: string }>();

    const verificarPagamento = useCallback(async () => {
        const data = new Date(dadosPix?.dataRegistro ?? new Date())
        const expiracao = new Date(data.setMinutes(data.getMinutes() + 30))
        try {
            if (fasePagamento) {
                const res = await getMovPagamentoById(dadosPix?.id ?? '', empresaId)
                if (res.erro) throw res.erro

                const data = res.resultado?.data

                if (data === StatusPagamento.Pago) {
                    setCancelarInterval(true)
                    setStatusPix(EnumStatusPix.Aceito)
                    await handlePedidoPix()

                    fecharSacola()
                    showSuccessMessage('Pedido enviado com sucesso!')
                    return
                } else if (new Date().getTime() >= expiracao.getTime()) {
                    setStatusPix(EnumStatusPix.Cancelado)
                    setCancelarInterval(true)
                    return
                }
            }
        } catch (err: any) {
            setCancelarInterval(true)
            setPedidoErro(true)
            showErrorMessage(err.message)
        }
    }, [dadosPix?.dataRegistro, dadosPix?.id, empresaId, fasePagamento, fecharSacola, getMovPagamentoById, handlePedidoPix, showErrorMessage, showSuccessMessage])

    useEffect(() => {
        if (fasePagamento) {
            const intervalId = setInterval(verificarPagamento, 10000)
            setIdInterval(intervalId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fasePagamento])

    useEffect(() => {
        if ((cancelarInterval && idInterval) || (idInterval && !fasePagamento)) {
            clearInterval(idInterval)
        }
    }, [cancelarInterval, fasePagamento, idInterval])

    useEffect(() => {
        if (!pedidoErro && fasePagamento) {
            const timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
            }, 1000);
            return () => {
                clearInterval(timer);
            };
        }
    }, [fasePagamento, pedidoErro]);

    const handleCancelar = useCallback(() => {
        setCancelarInterval(true)
        cancelarPedido()
        showSuccessMessage('Pedido cancelado!')
        fecharSacola()
    }, [cancelarPedido, fecharSacola, showSuccessMessage])

    const clickPedido = useCallback(async () => {
        try {
            setCancelarInterval(true)
            await handlePedido()
            fecharSacola()
            showSuccessMessage('Pedido enviado com sucesso!')
        } catch (err: any) {
            setPedidoErro(true)
            showErrorMessage(`Ocorreu um problema ao enviar seu pedido. Detalhe: ${err.message}`)
        }
    }, [fecharSacola, handlePedido, showErrorMessage, showSuccessMessage])

    const clickPedidoPix = useCallback(async () => {
        try {
            setCancelarInterval(true)
            await handlePedidoPix()
            fecharSacola()
            showSuccessMessage('Pedido enviado com sucesso!')
        } catch (err: any) {
            setPedidoErro(true)
            showErrorMessage(`Ocorreu um problema ao enviar seu pedido. Detalhe: ${err.message}`)
        }
    }, [fecharSacola, handlePedidoPix, showErrorMessage, showSuccessMessage])

    const clickPedidoConfirm = useCallback(() => {
        confirm({
            title: 'Pagar na Entrega',
            description: "Gostaria de pagar seu pedido na entrega?",
            confirmationText: "Sim",
            confirmationButtonProps: {
                variant: 'contained',
                color: 'primary',
                fullWidth: true
            },
            cancellationText: 'Não',
            cancellationButtonProps: {
                variant: 'outlined',
                color: theme.palette.type === 'dark' ? 'inherit' : 'primary',
                fullWidth: true
            }
        }).then(async () => await clickPedido())
    }, [clickPedido, confirm, theme.palette.type])

    return (
        <Slide direction={nextOrBack ? "left" : 'right'} mountOnEnter unmountOnExit in={fasePagamento}>
            <Box height={"100%"} width={"100%"} className={classes.boxContainer}>
                {statusPix === EnumStatusPix.Neutro && !carregandoHandlePedidoPix && !pedidoErro && (
                    <div className={classes.header}>
                        <Box textAlign={'center'}>
                            <LinearProgress className={classes.progressBar} variant="determinate" color={"primary"} value={progress} />
                            <Typography variant="caption" color="textSecondary">A cada 10 segundos verificamos se o pagamento foi efetuado.</Typography>
                        </Box>
                    </div>
                )}
                <div className={classes.scrollable} style={{
                    height: '100%',
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflowX: 'hidden'
                }}>
                    <PagamentoPixDetalhes
                        model={dadosPix ?? new MovRegistrarResponseModel()}
                        carregandoCriarPedido={carregandoHandlePedidoPix}
                        statusPix={statusPix}
                        fechar={fecharSacola}
                        pedidoErro={pedidoErro}
                        handlePedido={clickPedido}
                        handlePedidoPix={clickPedidoPix}
                        cancelarPedido={handleCancelar}
                    />
                </div>

                {statusPix === EnumStatusPix.Neutro && !carregandoHandlePedidoPix && !pedidoErro && (
                    <div className={classes.footer}>
                        <Divider style={{ marginTop: '1rem' }} />
                        <Grid container justify="center" className={classes.grdBtns}>
                            <Button
                                onClick={clickPedidoConfirm}
                                color={theme.palette.type === "light" ? "primary" : "inherit"}
                                variant="text"
                                size="large"
                                className={classNames(classes.btnFooter)}
                                fullWidth
                            >
                                <NaEntregaIcon tipo={theme.palette.type === "light" ? 'BUTTON' : 'BUTTON_PRIMARY'} />
                                Pagar na entrega (Maquininha)
                            </Button>
                        </Grid>
                    </div>
                )}
            </Box>
        </Slide>
    )
}