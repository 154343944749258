import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  makeStyles,
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  Checkbox,
  Divider,
  Box,
} from '@material-ui/core';
import { DeliveryDadosInstancia } from './componentes';
import { DeliveryDadosEntregaForm, DeliveryDadosClienteForm } from './componentes';
import { Modal } from 'componentes/modal';
import { DadosDelivery, EnumOpcoesDelivery } from 'storage/delivery/models';
import { useAtualizarDadosDelivery } from 'storage/delivery/mutations';
import { EmpresaModel } from 'api/cardapio/models/empresa/empresa-model';
import { OkIcon } from 'utils/Icons/ok-icon';
import { useEventTools } from 'hooks/event-tools/event-tools';
import { AppEventEnum } from 'hooks/event-tools/app-event-enum';
import { Carregando, useNotificacoes } from 'componentes';
import { useThemeQueries } from '../produtos/dialog-adicionar-produto-com-subitem/util-styles';
import { usePedidos } from 'componentes/providers/pedidos-provider';

const useStyles = makeStyles((theme) => ({
  contentModal: {
    padding: '16px 16px 5px 16px',
  },
  cardContentRoot: {
    padding: '5px',
    '&:last-child': {
      paddingBottom: '5px',
    },
  },
  carregando: {
    background: 'rgb(255 255 255 / 65%)'
  }
}));

export interface DeliveryModalProps {
  aberto: boolean;
  empresa: EmpresaModel | undefined;
  empresaId: string | undefined;
  dadosDelivery: DadosDelivery | undefined;
  quandoForFechado: any;
  permiteEntrega: boolean | undefined;
  permiteRetirada: boolean | undefined;
  fazerPedidoWhatsapp: (dadosDelivery?: DadosDelivery) => void;
  foiAbertoSacola: boolean;
  setFoiAbertoSacola: (value: boolean) => void;
  deliveryHabilitado: boolean
}

export const DeliveryModal = ({
  aberto,
  empresa,
  empresaId,
  dadosDelivery,
  quandoForFechado,
  permiteEntrega,
  permiteRetirada,
  fazerPedidoWhatsapp,
  foiAbertoSacola,
  setFoiAbertoSacola,
  deliveryHabilitado,
}: DeliveryModalProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(EnumOpcoesDelivery.Entrega);
  const { showErrorMessage } = useNotificacoes()
  const [foraArea, setForaArea] = useState<boolean>(false)
  const { addOuAttProdutoEntrega, carregando } = usePedidos()
  const { isMobile, theme } = useThemeQueries()

  const refRetirada = useRef<DeliveryDadosInstancia>(null);
  const refEntrega = useRef<DeliveryDadosInstancia>(null);

  const [atualizarDados] = useAtualizarDadosDelivery();
  const { callEvent } = useEventTools()

  useEffect(() => {
    if (dadosDelivery?.tipo === undefined) {
      setValue(
        permiteEntrega! === true ? EnumOpcoesDelivery.Entrega : EnumOpcoesDelivery.Retirada,
      );
    } else {
      if (dadosDelivery?.tipo === EnumOpcoesDelivery.Entrega) {
        setValue(
          permiteEntrega! === true ? EnumOpcoesDelivery.Entrega : EnumOpcoesDelivery.Retirada,
        );
      }
      if (dadosDelivery?.tipo === EnumOpcoesDelivery.Retirada) {
        setValue(
          permiteRetirada! === true ? EnumOpcoesDelivery.Retirada : EnumOpcoesDelivery.Entrega,
        );
      }
    }
  }, [aberto, dadosDelivery, permiteEntrega, permiteRetirada]);

  const handleChange = (panel: number) => {
    setValue(panel);
  };

  const fechar = () => {
    setFoiAbertoSacola(false)
    quandoForFechado(
      dadosDelivery === undefined ||
      (dadosDelivery?.tipo === EnumOpcoesDelivery.Entrega && permiteEntrega! === false) ||
      (dadosDelivery?.tipo === EnumOpcoesDelivery.Retirada && permiteRetirada! === false),
    );
  };

  const capitalize = useCallback((value: any) => {
    let splitted = value.split(' ');
    let formatado = '';
    splitted.forEach((element: string) => {
      formatado = formatado.concat(' ' + element.charAt(0).toUpperCase() + element.slice(1));
    });
    return formatado;
  }, []);

  const enderecoFormatado = useCallback(
    (lgr, nro, bairro, municipio, uf) => {
      return `${capitalize(lgr.toLowerCase())}, 
    ${capitalize(nro.toLowerCase())} - 
    ${capitalize(bairro.toLowerCase())}, 
    ${capitalize(municipio.toLowerCase())}/${uf}`;
    },
    [capitalize],
  );

  const confirmarForms = async () => {
    try {
      setForaArea(false)
      let dadosEntrega;
      const dadosCliente = refRetirada.current?.submit();
      if (value === EnumOpcoesDelivery.Entrega) {
        dadosEntrega = refEntrega.current?.submit();
      }
      if (deliveryHabilitado && (dadosEntrega?.sucesso || value === EnumOpcoesDelivery.Retirada)) {
        const deliveryDados = { ...dadosEntrega?.data } as DadosDelivery;
        deliveryDados.tipo = value;
        if (value === EnumOpcoesDelivery.Entrega) {
          const res = await addOuAttProdutoEntrega(deliveryDados)

          if (res === false) {
            setForaArea(true)
            return;
          }
        } else {

        }
        if (deliveryHabilitado) {
          atualizarDados({
            dados: {
              ...deliveryDados,
              nome: dadosDelivery?.nome ?? '',
              telefone: dadosDelivery?.telefone ?? '',
              documento: dadosDelivery?.documento ?? '',
            }, empresaId: empresaId!
          });
        } else {
          atualizarDados({ dados: deliveryDados, empresaId: empresaId! });
        }
        quandoForFechado();
        if (!foiAbertoSacola) {
          callEvent(AppEventEnum.InformadoEnderecoDelivery, true)
        }
        setFoiAbertoSacola(false)
        return
      }

      if (dadosCliente?.sucesso && (dadosEntrega?.sucesso || value === EnumOpcoesDelivery.Retirada)) {
        const deliveryDados = { ...dadosCliente?.data, ...dadosEntrega?.data } as DadosDelivery;
        deliveryDados.tipo = value;
        atualizarDados({ dados: deliveryDados, empresaId: empresaId! });
        fazerPedidoWhatsapp(deliveryDados)
        quandoForFechado();
      }
    } catch (error: any) {
      showErrorMessage(error.message)
    }
  };


  return (
    <Modal
      tamanho="md"
      aberto={aberto}
      fullScreen={isMobile}
      titulo="Dados do Pedido"
      quandoForFechado={fechar}
      zIndex="9999"
    >
      {carregando && (
        <Carregando tipo="spinner" mensagem='Verificando Endereço' className={classes.carregando} modelo="absolute" carregando={true} />
      )}
      {!deliveryHabilitado && (
        <DeliveryDadosClienteForm ref={refRetirada} dadosDelivery={dadosDelivery} modo={value} />
      )}
      <Grid container spacing={1} style={{ marginTop: '1rem' }}>
        {permiteRetirada && (
          <Grid item style={{ width: '100%' }}>
            <Card
              variant="outlined"
              style={{
                display: 'flex',
                border:
                  value === EnumOpcoesDelivery.Retirada
                    ? `1px solid ${theme.palette.primary.main}`
                    : '1px solid rgba(0, 0, 0, 0.12)',
              }}
              onClick={() => handleChange(EnumOpcoesDelivery.Retirada)}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  color="primary"
                  checked={value === EnumOpcoesDelivery.Retirada}
                  style={{ marginLeft: !isMobile ? '5px' : 'unset' }}
                />
              </div>
              <div>
                <CardContent classes={{ root: classes.cardContentRoot }}>
                  <Typography variant="h5">Retirar no Local</Typography>
                  <Typography variant="body1">
                    {enderecoFormatado(
                      empresa?.logradouro || '',
                      empresa?.numero || '',
                      empresa?.bairro || '',
                      empresa?.municipio || '',
                      empresa?.uf || '',
                    )}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
        )}

        {permiteEntrega && (
          <Grid item style={{ width: '100%' }}>
            <Card
              variant="outlined"
              style={{
                display: 'flex',
                border:
                  value === EnumOpcoesDelivery.Entrega
                    ? `1px solid ${theme.palette.primary.main}`
                    : '1px solid rgba(0, 0, 0, 0.12)',
              }}
              onClick={() => handleChange(EnumOpcoesDelivery.Entrega)}
            >
              <CardContent classes={{ root: classes.cardContentRoot }}>
                <Typography variant="h5" style={{ display: isMobile ? 'flex' : 'initial' }}>
                  <Checkbox checked={value === EnumOpcoesDelivery.Entrega} color="primary" />
                  Entregar no Meu Endereço
                </Typography>
                {foraArea && (
                  <Box ml={isMobile ? '11px' : 'unset'} bgcolor={theme.palette.error.main} mb={2} borderRadius={8} py={1} px={2} width="100%">
                    <Typography>
                      <Box fontWeight={400} color={theme.palette.common.white}>
                        Infelizmente sua localização está fora da área de entrega.
                      </Box>
                    </Typography>
                  </Box>
                )}
                <div style={{ marginLeft: !isMobile ? '11px' : 'unset' }}>
                  <DeliveryDadosEntregaForm ref={refEntrega} dadosDelivery={dadosDelivery} />
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <Divider style={{ marginTop: '1rem' }} />
      <Grid container justify="flex-end" style={{ marginTop: '.5rem' }} onClick={confirmarForms}>
        <Button color="primary" size="large" startIcon={<OkIcon tipo="BUTTON_PRIMARY" />}>Confirmar</Button>
      </Grid>
    </Modal>
  );
};