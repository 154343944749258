import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  Drawer,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  useObterSacola,
  useObterDadosDelivery,
  useEsvaziarSacola,
  ItemSacola,
  DadosDelivery,
} from 'storage';
import { VisualizarProdutoModal } from 'modulos/cardapio/componentes/produtos/visualizar-produto-modal';
import { MergedCardapio } from 'api/merged';
import { generatePedidoWhatsApp } from 'utils';
import { useStyles } from './cardapio-sacola-styles';
import { ProdutoModel } from 'api/cardapio/models/produto/produto-model';
import { TemaModel } from 'api/cardapio/models/tema/tema-model';
import { DeliveryModal } from 'modulos/cardapio/componentes';
import { Sacola } from './componentes/fases/sacola/sacola';
import { DialogScannerComanda } from '../cardapio-scanner-comanda';
import { usePedidos } from 'componentes/providers/pedidos-provider';
import classNames from 'classnames';
import { MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import { DialogAdicionarProdutoComSubItem } from 'modulos/cardapio/componentes/produtos/dialog-adicionar-produto-com-subitem/dialog-adicionar-produto-com-subitem';
import { useMovProd } from 'hooks/mov-prod';
import { IdentificacaoCliente } from './componentes/fases/identificacao-cliente/identificacao-cliente';
import { FormasPagamento } from './componentes/fases/formas-pagamento/formas-pagamento';
import { PagamentoPix } from './componentes/fases/pagamento-pix/pagamento-pix';
import { FazendoPedido } from './componentes/fases/fazendo-pedido/fazendo-pedido';
import { ConfigEmpresaModel } from 'api/cardapio/models/empresa/empresa-model';
import { isEmpty } from 'lodash';

export interface CarddapioSacolaProps {
  empresaDados: MergedCardapio | undefined;
  empresaId: string;
  mesaId: string;
  sacolaAberta: boolean;
  statusPedido: boolean;
  quandoFechado: () => any;
  tema: TemaModel
  abrirModalCliente: () => void;
  logicaDados: any
  logica: any
  handleFazerPedido: () => Promise<void>;
  configEmp: ConfigEmpresaModel[];
  produtos: ProdutoModel[];
}

export enum EnumTipoBuild {
  Padaria,
  Shopping
}

export enum EnumFasesSacola {
  Nada,
  Sacola,
  IdentificarCliente,
  FormaPagamento,
  PagamentoPix,
  FazendoPedido
}

// const tipoBuild: EnumTipoBuild = TipoBuildEnv === "shopping" ? EnumTipoBuild.Shopping : EnumTipoBuild.Padaria

export const CardapioSacola = ({
  empresaDados,
  empresaId,
  mesaId,
  sacolaAberta,
  quandoFechado,
  abrirModalCliente,
  logicaDados,
  logica,
  tema,
  handleFazerPedido,
  configEmp,
  produtos
}: CarddapioSacolaProps) => {
  // DEPENDENCIAS
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const variant = isMobile ? 'temporary' : 'temporary';
  const anchor = isMobile ? 'bottom' : 'right';

  // REQUISIÇÕES
  const itens = useObterSacola(empresaId);
  const deliveryDados = useObterDadosDelivery({ empresaId: empresaId });
  const [esvaziarSacola] = useEsvaziarSacola();
  const { adicionarProdutoComSubItem, editarProdutoComSubItem } = useMovProd()

  // STATES E REFS
  const [editarProdPedidoAberto, setEditarProdPedidoAberto] = useState(false);
  const [produto, setProduto] = useState<MovSimplesProdutoModel>(new MovSimplesProdutoModel());
  const dadosEntregaPedido = useRef<DadosDelivery | undefined>(undefined);
  const [modalDeliveryAberto, setModalDeliveryAberto] = useState(false);
  const [faseSacola, setFaseSacola] = useState<EnumFasesSacola>(EnumFasesSacola.Sacola)
  const [scannerComanda, setScannerComanda] = useState<boolean>(false)
  const [dialogSubitem, setDialogSubItem] = useState<boolean>(false)
  const editarUsuarioEnderecoRef = useRef<boolean>(false)
  const { setCodComanda, codComanda } = usePedidos()
  const [foiAbertoSacola, setFoiAbertoSacola] = useState<boolean>(false)
  const tipoDelivery = [
    empresaDados?.configuracoes?.permiteEntrega,
    empresaDados?.configuracoes?.permiteRetirada
  ].includes(true)
  const deliveryHabilitado = empresaDados?.configuracoesDelivery.deliveryHabilitado ?? false

  useEffect(() => {
    if (sacolaAberta) setFaseSacola(EnumFasesSacola.Sacola)
  }, [sacolaAberta])

  //é bixo, isso aqui estamos usando ref pq o state não atualiza a tempo de trocar(caso altere o endereço/retirada) quando clica em fazer pedido,
  //aí quando editava, sempre passava o antes de editar, usando ref resolveu
  useEffect(() => {
    dadosEntregaPedido.current = deliveryDados.data;
  }, [deliveryDados]);

  const fecharModalDelivery = useCallback(
    (fecharSacola?: boolean) => {
      setModalDeliveryAberto(false);
      if (fecharSacola) {
        quandoFechado();
      }
    },
    [quandoFechado],
  );

  const fecharEdicaoProduto = useCallback(() => {
    setEditarProdPedidoAberto(false);
    setProduto(new MovSimplesProdutoModel())
  }, []);


  useEffect(() => {
    document.body.classList.toggle(classes.scrollPage);
  }, [classes.scrollPage]);

  const total = useCallback(() => {
    let total =
      (itens?.data || ([] as ProdutoModel[])).length > 0
        ? itens?.data?.filter(x => x.indFin).map((item) => {
          if (item.vUnCom === 0)
            return 0;
          return item.vFinal!
        }).reduce((prev, next) => prev + next, 0)
        : 0;

    return (total || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }, [itens]);

  const totalItens = useCallback(() => {
    let total =
      (itens?.data || ([] as ItemSacola[])).length > 0
        ? itens?.data?.map((item) => item.qCom).reduce((prev, next) => prev! + next!, 0)
        : 0;

    return total || 0;
  }, [itens]);

  const fazerPedidoWhatsApp = useCallback((dadosDelivery?: DadosDelivery) => {
    let link = generatePedidoWhatsApp().gerarPedido(
      empresaDados?.empresa?.nomeFantasia || '',
      empresaDados?.empresa?.fone || '',
      itens?.data || ([] as MovSimplesProdutoModel[]),
      totalItens(),
      total(),
      empresaDados?.configuracoes?.permiteEntrega === true,
      empresaDados?.configuracoes?.permiteRetirada === true,
      dadosDelivery ?? dadosEntregaPedido.current,
    );
    window.open(link);
    esvaziarSacola({ empresaId: empresaId });
    quandoFechado();
  }, [
    empresaDados?.configuracoes?.permiteEntrega,
    empresaDados?.configuracoes?.permiteRetirada,
    empresaDados?.empresa?.fone,
    empresaDados?.empresa?.nomeFantasia,
    empresaId,
    esvaziarSacola,
    itens?.data,
    quandoFechado,
    total,
    totalItens
  ]);


  const entrarFases = useCallback((fase: EnumFasesSacola) => {
    setFaseSacola(EnumFasesSacola.Nada)
    setTimeout(() => {
      setFaseSacola(fase)
    }, 300)
  }, [])

  const abrirModalDelivery = useCallback((abertoSacola?: boolean) => {
    if (abertoSacola) {
      setFoiAbertoSacola(true)
    }
    setModalDeliveryAberto(true);
  }, []);

  const editarItem = useCallback((item: MovSimplesProdutoModel) => {
    setProduto(item);
    if (item.subItens.length === 0) {
      setEditarProdPedidoAberto(true)
      return
    }
    setDialogSubItem(true)
  }, []);

  const handleCodigo = (codigo: string) => {
    const prefixo = configEmp.find(x => x.codigo === 804)
    if (prefixo && prefixo.valor && codigo.startsWith(prefixo.valor)) {
      codigo = codigo.slice(prefixo.valor.length)
    }
    setCodComanda(codigo)

    if (empresaDados?.configuracoes.disponibilizarPagamentoFinalizacao) {
      entrarFases(EnumFasesSacola.FormaPagamento)
      return;
    }

    entrarFases(EnumFasesSacola.FazendoPedido)
  }

  const handlePedidoFluxo = useCallback(() => {
    if (mesaId) {
      if (empresaDados?.configuracoes.tipoUsoCardapio === 1 && isEmpty(codComanda)) {
        setScannerComanda(true)
        return
      }
    }

    if ((empresaDados?.configuracoes.disponibilizarPagamentoFinalizacao && mesaId) || (!mesaId && deliveryHabilitado)) {
      entrarFases(EnumFasesSacola.FormaPagamento)
      return;
    }

    entrarFases(EnumFasesSacola.FazendoPedido)
  }, [codComanda, deliveryHabilitado, empresaDados?.configuracoes.disponibilizarPagamentoFinalizacao, empresaDados?.configuracoes.tipoUsoCardapio, entrarFases, mesaId])

  return (
    <>
      <Drawer
        variant={variant}
        anchor={anchor}
        open={sacolaAberta}
        classes={{ paper: classNames(classes.drawer) }}
        style={{
          overflowX: 'hidden'
        }}
      >
        <div className={isMobile ? classes.drawerBg : ''}></div>
        <Sacola
          fazerPedidoWhatsApp={fazerPedidoWhatsApp}
          quandoFechado={quandoFechado}
          empresaId={empresaId}
          empresaDados={empresaDados}
          abrirModalCliente={abrirModalCliente}
          mesaId={mesaId}
          sacolaAberta={sacolaAberta}
          abrirModalDelivery={abrirModalDelivery}
          editarItem={editarItem}
          faseSacola={faseSacola}
          entrarFases={entrarFases}
          handlePedidoFluxo={handlePedidoFluxo}
          configEmp={configEmp}
          tipoDelivery={tipoDelivery}
          pedidoDelivery={deliveryHabilitado}
          editarUsuarioEnderecoRef={(editar: boolean) => editarUsuarioEnderecoRef.current = editar}
        />
        <IdentificacaoCliente
          mesaId={mesaId}
          fazerPedido={handlePedidoFluxo}
          faseSacola={faseSacola}
          dadosDelivery={deliveryDados.data!}
          entrarFases={entrarFases}
          fecharSacola={quandoFechado}
          fecharModalDelivery={fecharModalDelivery}
          abrirModalDelivery={abrirModalDelivery}
          tipoDelivery={tipoDelivery}
          pedidoDelivery={deliveryHabilitado}
          editarUsuarioEnderecoRef={editarUsuarioEnderecoRef.current}
        />
        <FormasPagamento
          faseSacola={faseSacola}
          dadosDelivery={deliveryDados.data!}
          entrarFases={entrarFases}
          fecharSacola={quandoFechado}
          empresaId={empresaId}
          empresaDados={empresaDados}
          mesaId={mesaId}
        />
        <PagamentoPix
          entrarFases={entrarFases}
          faseSacola={faseSacola}
          fecharSacola={quandoFechado}
        />
        <FazendoPedido
          entrarFases={entrarFases}
          faseSacola={faseSacola}
          fecharSacola={quandoFechado}
          setScannerComanda={setScannerComanda}
          handlePedidoFluxo={handlePedidoFluxo}
        />
      </Drawer >
      <DeliveryModal
        aberto={modalDeliveryAberto}
        empresa={empresaDados?.empresa!}
        empresaId={empresaId!}
        dadosDelivery={deliveryDados.data!}
        quandoForFechado={fecharModalDelivery}
        permiteEntrega={empresaDados?.configuracoes.permiteEntrega!}
        permiteRetirada={empresaDados?.configuracoes.permiteRetirada!}
        fazerPedidoWhatsapp={fazerPedidoWhatsApp}
        foiAbertoSacola={foiAbertoSacola}
        setFoiAbertoSacola={setFoiAbertoSacola}
        deliveryHabilitado={deliveryHabilitado}
      />
      <VisualizarProdutoModal
        aberto={editarProdPedidoAberto}
        produto={produto}
        empresaDados={empresaDados}
        mesaId={mesaId}
        quandoForFechado={fecharEdicaoProduto}
      />

      {scannerComanda && (
        <DialogScannerComanda
          openned={scannerComanda}
          statusPedido={false}
          closeModal={() => setScannerComanda(false)}
          empresaDados={empresaDados}
          empresaId={empresaId}
          mesaId={mesaId}
          handleCodigo={handleCodigo}
        />
      )}

      {dialogSubitem && (
        <DialogAdicionarProdutoComSubItem
          aberto={dialogSubitem}
          adicionarProdutoComSubItem={adicionarProdutoComSubItem}
          editarProdutoComSubItem={editarProdutoComSubItem}
          fechar={() => setDialogSubItem(false)}
          produto={produto}
          edit
        />
      )}
    </>
  );
};
