import React, { useCallback } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { Typography, Grid, Divider, Box } from '@material-ui/core';
import { DadosDelivery, EnumOpcoesDelivery } from 'storage';
import { useStyles } from './opcoes-delivery-styles';
import { EmpresaModel } from 'api/cardapio/models/empresa/empresa-model';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';

export interface OpcoesDeliveryProps {
  deliveryDados: DadosDelivery | undefined;
  empresa: EmpresaModel | undefined;
  permiteEntrega?: boolean;
  permiteRetirada?: boolean;
  quandoClicar: (abertoSacola?: boolean) => void;
  deliveryHabilitado: boolean
}
export const OpcoesDelivery = ({
  deliveryDados,
  empresa,
  permiteEntrega,
  permiteRetirada,
  quandoClicar,
  deliveryHabilitado,
}: OpcoesDeliveryProps) => {
  const classes = useStyles();

  const capitalize = useCallback((value: any) => {
    let splitted = value.split(' ');
    let formatado = '';
    splitted.forEach((element: string) => {
      formatado = formatado.concat(' ' + element.charAt(0).toUpperCase() + element.slice(1));
    });
    return formatado;
  }, []);
  const enderecoFormatado = useCallback(
    (lgr, nro, bairro, municipio, uf) => {
      return `${capitalize(lgr.toLowerCase())}, 
    ${capitalize(nro.toLowerCase())} - 
    ${capitalize(bairro.toLowerCase())}, 
    ${capitalize(municipio.toLowerCase())}/${uf}`;
    },
    [capitalize],
  );

  if (
    (deliveryDados?.tipo === EnumOpcoesDelivery.Entrega && permiteEntrega === false) ||
    (deliveryDados?.tipo === EnumOpcoesDelivery.Retirada && permiteRetirada === false)
  ) {
    return <></>;
  }
  return (
    <>
      {deliveryDados && (permiteEntrega === true || permiteRetirada === true) && (
        <>
          <Divider style={{ marginBottom: '.5rem' }} />
          <Grid onClick={() => quandoClicar(true)} className={classes.root}>
            <Grid container justify="space-between">
              <Typography variant="h5" color="primary">
                Dados do Pedido - {deliveryDados?.tipo === EnumOpcoesDelivery.Entrega
                  ? 'Entrega '
                  : 'Retirada '}
              </Typography>
              <EditIcon color="primary" />
            </Grid>
            {/* <Divider className={classes.divider} /> */}
            {deliveryHabilitado && (
              <Box pb={1}>
                <Box pt={1} pb={.5}>
                  <Typography variant="body1" color="primary">
                    Cliente: {formatarCPFCNPJ(deliveryDados.documento)} - {deliveryDados.nome}
                  </Typography>
                </Box>
                <Divider className={classes.divider} />
              </Box>
            )}
            <Typography variant="body1" color="primary">
              {deliveryDados?.tipo === EnumOpcoesDelivery.Entrega
                ? (
                  (
                    deliveryDados?.lgr &&
                    deliveryDados?.nro &&
                    deliveryDados?.bairro &&
                    deliveryDados?.municipio &&
                    deliveryDados?.uf
                  ) ?
                    enderecoFormatado(
                      deliveryDados?.lgr || '',
                      deliveryDados?.nro || '',
                      deliveryDados?.bairro || '',
                      deliveryDados?.municipio || '',
                      deliveryDados?.uf || '',
                    ) : ''
                )
                : enderecoFormatado(
                  empresa?.logradouro || '',
                  empresa?.numero || '',
                  empresa?.bairro || '',
                  empresa?.municipio || '',
                  empresa?.uf || '',
                )}
            </Typography>

            {!deliveryHabilitado && deliveryDados?.tipo === EnumOpcoesDelivery.Retirada && (
              <Typography variant="body1" color="primary">
                <strong>Pessoa a retirar:</strong> {deliveryDados?.nome}
              </Typography>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
