import { VariaveisAmbiente } from "ambiente";
import { useApiBase } from "api/base";
import { CalcularFreteModel } from "api/cardapio/models/geolocalizacao/calcular-frete-model";
import { useCallback } from "react";

export function usePostCalcularFrete() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postCalcularFrete = useCallback(
        (localizacao: CalcularFreteModel, empresaId: string) => {
            return invocarApi({
                url: `api/v4/empresa/${empresaId}/calcular-frete`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Domain': 'app.touchone.com.br',
                },
                data: JSON.stringify(localizacao),
                enviarTokenUsuario: false,
                baseURL: VariaveisAmbiente.masterApi,
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        postCalcularFrete,
    };
}