import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Paper,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { getIn, useFormik } from 'formik';
import { useNovaPessoaFormValidation } from './nova-pessoa-form-validation';
import { NovaPessoaFormProps, NovaPessoaFormPropsRef } from './nova-pessoa-form-props';
import { useStyles } from './nova-pessoa-form-styles';
import classNames from 'classnames';
import { PessoaModelForm } from 'api/cardapio/models/pessoa/pessoa-model';
import { TextFieldSaurus } from 'componentes/inputs/text-field-saurus';
import { usePedidos } from 'componentes/providers/pedidos-provider';
import { EnumTipoContato } from 'api/cardapio/models/enums/enum-tipo-contato';

export const NovaPessoaForm = React.forwardRef<NovaPessoaFormPropsRef, NovaPessoaFormProps>(
  ({ model, ...props }: NovaPessoaFormProps, ref) => {

    // DEPENDENCIAS
    const classes = useStyles();
    const theme = useTheme()
    const { getCliente } = usePedidos();
    const clienteIncompleto = getCliente();
    const [modelo, setModelo] = useState<PessoaModelForm>(new PessoaModelForm())
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
      setModelo({
        cpfcnpj: model,
        nome: clienteIncompleto?.nome ?? '',
        email: clienteIncompleto?.contatos?.find(contato => contato.tipo === EnumTipoContato.EMAIL)?.valor ?? '',
        telefone: clienteIncompleto?.contatos?.find(contato => contato.tipo === EnumTipoContato.TELEFONE)?.valor ?? ''
      })
    }, [clienteIncompleto?.contatos, clienteIncompleto?.nome, model])

    const onSubmit = (value: PessoaModelForm) => {
      props.onSubmit(value)
    }

    const { novaPessoaFormValidation } = useNovaPessoaFormValidation(props.pedidoDelivery && props.tipoDelivery)
    
    const {
      errors,
      touched,
      handleSubmit,
      handleChange,
      handleBlur,
      values
    } = useFormik({
      enableReinitialize: true,
      validationSchema: novaPessoaFormValidation,
      initialValues: modelo,
      onSubmit: onSubmit,
    });

    React.useImperativeHandle(ref, () => ({
      submit: () => {
        handleSubmit()
      },
    }));

    return (
      <>
        <Paper
          className={classNames(classes.form)}
          elevation={0}
          component="form"
          onSubmit={handleSubmit as any}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={5}>
              <TextFieldSaurus
                label="CPF/CNPJ"
                name="cpfcnpj"
                variant="outlined"
                tipo="CNPJ_CPF"
                onChange={handleChange}
                className={isMobile ? classes.textFieldAlignCenter : classes.textFieldColorPrimary}
                readOnly
                onBlur={handleBlur}
                allowSubmit
                value={values.cpfcnpj}
                error={Boolean(
                  getIn(touched, 'cpfcnpj') &&
                  getIn(errors, 'cpfcnpj'),
                )}
                helperText={
                  getIn(touched, 'cpfcnpj') &&
                    getIn(errors, 'cpfcnpj')
                    ? getIn(errors, 'cpfcnpj')
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <TextFieldSaurus
                label="Nome Completo"
                name="nome"
                variant="outlined"
                onChange={handleChange}
                tipo="TEXTO"
                onBlur={handleBlur}
                allowSubmit
                value={values.nome}
                error={Boolean(
                  getIn(touched, 'nome') &&
                  getIn(errors, 'nome'),
                )}
                helperText={
                  getIn(touched, 'nome') &&
                    getIn(errors, 'nome')
                    ? getIn(errors, 'nome')
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextFieldSaurus
                manterMascara
                label="Telefone"
                name="telefone"
                variant="outlined"
                tipo="TELEFONE"
                allowSubmit
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.telefone}
                error={Boolean(
                  getIn(touched, 'telefone') &&
                  getIn(errors, 'telefone'),
                )}
                helperText={
                  getIn(touched, 'telefone') &&
                    getIn(errors, 'telefone')
                    ? getIn(errors, 'telefone')
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <TextFieldSaurus
                label="E-mail"
                name="email"
                tipo="EMAIL"
                variant="outlined"
                allowSubmit
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={Boolean(
                  getIn(touched, 'email') &&
                  getIn(errors, 'email'),
                )}
                helperText={
                  getIn(touched, 'email') &&
                    getIn(errors, 'email')
                    ? getIn(errors, 'email')
                    : undefined
                }
              />
            </Grid>
            <Button type="submit" style={{ display: 'none' }}></Button>
          </Grid>
        </Paper>
      </>
    );
  },
);