import React from 'react';
import { BottomNavigation, Typography } from '@material-ui/core';
import { useObterSacola, ItemSacola, storageCardapio, CardapioStorageKeys } from 'storage';

import { useStyles } from './bottom-sacola-styles';


export interface BottomSacolaProps {
  empresaId: string;
  quandoClicado?: () => any;
}

const tokenIntegrador = storageCardapio.load(
  CardapioStorageKeys[CardapioStorageKeys.TokenIntegrador].toString(),
);

export const BottomSacola = ({ empresaId, quandoClicado }: BottomSacolaProps) => {
  const itens = useObterSacola(tokenIntegrador.empresaId);
  const classes = useStyles();
  const totalSacola = React.useCallback(() => {
    let total =
      (itens?.data || ([] as ItemSacola[])).length > 0
        ? itens?.data?.map((item) => item.vUnCom! * item.qCom!).reduce((prev, next) => prev + next)
        : 0;

    return (total || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }, [itens]);
  return (
    <>
      {(itens?.data || []).length > 0 ? (
        <div onClick={quandoClicado}>
          <BottomNavigation className={classes.root}>
            <Typography variant="h6" className={classes.textos}>Sacola</Typography>

            <Typography variant="h6" className={classes.textos}>{totalSacola()}</Typography>
          </BottomNavigation>
        </div>
      ) : null}
    </>
  );
};
