import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    scrollable: {
        position: 'relative',
        overflowY: 'auto',
        marginTop: '.5rem',
        [theme.breakpoints.down('sm')]: {},
        '&::-webkit-scrollbar': {
            width: '18px',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            backgroundColor: 'transparent ',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: '20px',
            border: '6px solid transparent',
            backgroundClip: 'content-box',
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,.15)',
            },
        },
    },
    textLoad: {
        fontWeight: 600,
        textAlign: 'center',
        fontSize: '1.8rem'
    }
}))